#grade-calculator {
  text-align: left;
  margin: 0 auto;
  padding-left: 25px;
  /* set the background to brown of the entire app */
  background-color: white;


}

