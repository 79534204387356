.grade-calculator{
    text-align: auto;
    margin: 0 auto;
}

/* make this for mobile only */

@media only screen and (max-width: 600px) {
    .grade-calculator ul {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding: 0;
            margin: 0;
    }
}


@media only screen and (min-width: 601px) {
    .grade-calculator ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0;
        margin: 0;
    }
}

